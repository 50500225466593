import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private http: HttpClient = inject(HttpClient)
  
  getAllFooterSectionsWithPages( ): any {
    return this.http.get(`${environment.apiEndPoint}/Tenant/ShopPages/GetSectionsWithPages`);
  }
}
