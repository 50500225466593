<p-dialog class="age-consent" [(visible)]="displayModal" [breakpoints]="{ '960px': '75vw', '768px': '92vw' }"
  [resizable]="false" [closable]="false" [modal]="true" [header]="" [baseZIndex]="9999" (onShow)="onShow()">

  

    <!-- <ng-template pTemplate="header"> -->
    <div class="age-modal">
      <div class="age-header">
        <div class="age-data">
          <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
            <g filter="url(#filter0_d_52348_42654)">
              <circle cx="60" cy="56" r="48" fill="white" />
            </g>
            <circle cx="60.0001" cy="56.0001" r="45.0783" fill="#F2F2F2" />
            <defs>
              <filter id="filter0_d_52348_42654" x="0" y="0" width="120" height="120" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_52348_42654" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_52348_42654" result="shape" />
              </filter>
            </defs>
          </svg>
          <div class="age-value">
            <span class="age-text">{{age}}+</span>
          </div>
        </div>
        <h1 class="heading">
          {{'ageConsentModal.ageVerification' | translate}}
        </h1>
      </div>

      <div class="warning-lable">
        <div class="warning-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#191C1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.25 11.25H12V16.5H12.75" stroke="#191C1F" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
              fill="#191C1F" />
          </svg>
        </div>
        <div class="warning-text">
          <p>{{eligibilityWarningLabel}}</p>
        </div>
      </div>

      <div style="width: 100%;">
        <p id="body-text">
          {{'ageConsentModal.PleaseVerify' | translate}}
        </p>
        <div class="dropdown-select">
          <div class="dropdowns">
            <label class="dropdown-label">
              {{'ageConsentModal.Day' | translate}}*
            </label>
            <div class="col-flex">              
              <select [(ngModel)]="selectedDay" (change)="validateDoB()">
                <option *ngIf="selectedDay === undefined" value="" selected></option>
                <option *ngFor="let day of days" [value]="day">{{ day }}</option>
              </select>
              <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M12.6668 2.66732L11.3335 2.66732V2.00065C11.3335 1.82384 11.2633 1.65427 11.1382 1.52925C11.0132 1.40422 10.8436 1.33398 10.6668 1.33398C10.49 1.33398 10.3204 1.40422 10.1954 1.52925C10.0704 1.65427 10.0002 1.82384 10.0002 2.00065V2.66732L6.00016 2.66732V2.00065C6.00016 1.82384 5.92992 1.65427 5.8049 1.52925C5.67988 1.40422 5.51031 1.33398 5.3335 1.33398C5.15669 1.33398 4.98712 1.40422 4.86209 1.52925C4.73707 1.65427 4.66683 1.82384 4.66683 2.00065V2.66732L3.3335 2.66732C2.80306 2.66732 2.29436 2.87803 1.91928 3.2531C1.54421 3.62818 1.3335 4.13688 1.3335 4.66732L1.3335 12.6673C1.3335 13.1978 1.54421 13.7065 1.91928 14.0815C2.29436 14.4566 2.80306 14.6673 3.3335 14.6673L12.6668 14.6673C13.1973 14.6673 13.706 14.4566 14.081 14.0815C14.4561 13.7065 14.6668 13.1978 14.6668 12.6673L14.6668 4.66732C14.6668 4.13688 14.4561 3.62818 14.081 3.2531C13.706 2.87803 13.1973 2.66732 12.6668 2.66732ZM13.3335 12.6673C13.3335 12.8441 13.2633 13.0137 13.1382 13.1387C13.0132 13.2637 12.8436 13.334 12.6668 13.334L3.3335 13.334C3.15669 13.334 2.98712 13.2637 2.86209 13.1387C2.73707 13.0137 2.66683 12.8441 2.66683 12.6673V8.00065H13.3335L13.3335 12.6673ZM13.3335 6.66732L2.66683 6.66732L2.66683 4.66732C2.66683 4.49051 2.73707 4.32094 2.86209 4.19591C2.98712 4.07089 3.15669 4.00065 3.3335 4.00065H4.66683V4.66732C4.66683 4.84413 4.73707 5.0137 4.86209 5.13872C4.98712 5.26375 5.15669 5.33398 5.3335 5.33398C5.51031 5.33398 5.67988 5.26375 5.8049 5.13872C5.92992 5.0137 6.00016 4.84413 6.00016 4.66732V4.00065L10.0002 4.00065V4.66732C10.0002 4.84413 10.0704 5.0137 10.1954 5.13872C10.3204 5.26375 10.49 5.33398 10.6668 5.33398C10.8436 5.33398 11.0132 5.26375 11.1382 5.13872C11.2633 5.0137 11.3335 4.84413 11.3335 4.66732V4.00065L12.6668 4.00065C12.8436 4.00065 13.0132 4.07089 13.1382 4.19591C13.2633 4.32094 13.3335 4.49051 13.3335 4.66732V6.66732Z"
                  fill="#030303" />
              </svg>
            </div>           
          </div>
          <div class="dropdowns">
            <label class="dropdown-label">
              {{'ageConsentModal.Month' | translate}}*
            </label>
            <div class="col-flex">              
              <select [(ngModel)]="selectedMonth" (change)="onMonthOrYearChange()">
                <option *ngIf="(selectedMonth === undefined || selectedMonth == '')" value="" selected></option>
                <option *ngFor="let month of months" [value]="month">{{ month }}</option>
              </select>
              <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M12.6668 2.66732L11.3335 2.66732V2.00065C11.3335 1.82384 11.2633 1.65427 11.1382 1.52925C11.0132 1.40422 10.8436 1.33398 10.6668 1.33398C10.49 1.33398 10.3204 1.40422 10.1954 1.52925C10.0704 1.65427 10.0002 1.82384 10.0002 2.00065V2.66732L6.00016 2.66732V2.00065C6.00016 1.82384 5.92992 1.65427 5.8049 1.52925C5.67988 1.40422 5.51031 1.33398 5.3335 1.33398C5.15669 1.33398 4.98712 1.40422 4.86209 1.52925C4.73707 1.65427 4.66683 1.82384 4.66683 2.00065V2.66732L3.3335 2.66732C2.80306 2.66732 2.29436 2.87803 1.91928 3.2531C1.54421 3.62818 1.3335 4.13688 1.3335 4.66732L1.3335 12.6673C1.3335 13.1978 1.54421 13.7065 1.91928 14.0815C2.29436 14.4566 2.80306 14.6673 3.3335 14.6673L12.6668 14.6673C13.1973 14.6673 13.706 14.4566 14.081 14.0815C14.4561 13.7065 14.6668 13.1978 14.6668 12.6673L14.6668 4.66732C14.6668 4.13688 14.4561 3.62818 14.081 3.2531C13.706 2.87803 13.1973 2.66732 12.6668 2.66732ZM13.3335 12.6673C13.3335 12.8441 13.2633 13.0137 13.1382 13.1387C13.0132 13.2637 12.8436 13.334 12.6668 13.334L3.3335 13.334C3.15669 13.334 2.98712 13.2637 2.86209 13.1387C2.73707 13.0137 2.66683 12.8441 2.66683 12.6673V8.00065H13.3335L13.3335 12.6673ZM13.3335 6.66732L2.66683 6.66732L2.66683 4.66732C2.66683 4.49051 2.73707 4.32094 2.86209 4.19591C2.98712 4.07089 3.15669 4.00065 3.3335 4.00065H4.66683V4.66732C4.66683 4.84413 4.73707 5.0137 4.86209 5.13872C4.98712 5.26375 5.15669 5.33398 5.3335 5.33398C5.51031 5.33398 5.67988 5.26375 5.8049 5.13872C5.92992 5.0137 6.00016 4.84413 6.00016 4.66732V4.00065L10.0002 4.00065V4.66732C10.0002 4.84413 10.0704 5.0137 10.1954 5.13872C10.3204 5.26375 10.49 5.33398 10.6668 5.33398C10.8436 5.33398 11.0132 5.26375 11.1382 5.13872C11.2633 5.0137 11.3335 4.84413 11.3335 4.66732V4.00065L12.6668 4.00065C12.8436 4.00065 13.0132 4.07089 13.1382 4.19591C13.2633 4.32094 13.3335 4.49051 13.3335 4.66732V6.66732Z"
                  fill="#030303" />
              </svg>
            </div>           
          </div>
          <div class="dropdowns">
            <label class="dropdown-label">
              {{'ageConsentModal.Year' | translate}}*
            </label>
            <div class="col-flex">              
              <select [(ngModel)]="selectedYear" (change)="onMonthOrYearChange()">
                <option *ngIf="selectedYear === undefined" value="" selected></option>
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
              <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M12.6668 2.66732L11.3335 2.66732V2.00065C11.3335 1.82384 11.2633 1.65427 11.1382 1.52925C11.0132 1.40422 10.8436 1.33398 10.6668 1.33398C10.49 1.33398 10.3204 1.40422 10.1954 1.52925C10.0704 1.65427 10.0002 1.82384 10.0002 2.00065V2.66732L6.00016 2.66732V2.00065C6.00016 1.82384 5.92992 1.65427 5.8049 1.52925C5.67988 1.40422 5.51031 1.33398 5.3335 1.33398C5.15669 1.33398 4.98712 1.40422 4.86209 1.52925C4.73707 1.65427 4.66683 1.82384 4.66683 2.00065V2.66732L3.3335 2.66732C2.80306 2.66732 2.29436 2.87803 1.91928 3.2531C1.54421 3.62818 1.3335 4.13688 1.3335 4.66732L1.3335 12.6673C1.3335 13.1978 1.54421 13.7065 1.91928 14.0815C2.29436 14.4566 2.80306 14.6673 3.3335 14.6673L12.6668 14.6673C13.1973 14.6673 13.706 14.4566 14.081 14.0815C14.4561 13.7065 14.6668 13.1978 14.6668 12.6673L14.6668 4.66732C14.6668 4.13688 14.4561 3.62818 14.081 3.2531C13.706 2.87803 13.1973 2.66732 12.6668 2.66732ZM13.3335 12.6673C13.3335 12.8441 13.2633 13.0137 13.1382 13.1387C13.0132 13.2637 12.8436 13.334 12.6668 13.334L3.3335 13.334C3.15669 13.334 2.98712 13.2637 2.86209 13.1387C2.73707 13.0137 2.66683 12.8441 2.66683 12.6673V8.00065H13.3335L13.3335 12.6673ZM13.3335 6.66732L2.66683 6.66732L2.66683 4.66732C2.66683 4.49051 2.73707 4.32094 2.86209 4.19591C2.98712 4.07089 3.15669 4.00065 3.3335 4.00065H4.66683V4.66732C4.66683 4.84413 4.73707 5.0137 4.86209 5.13872C4.98712 5.26375 5.15669 5.33398 5.3335 5.33398C5.51031 5.33398 5.67988 5.26375 5.8049 5.13872C5.92992 5.0137 6.00016 4.84413 6.00016 4.66732V4.00065L10.0002 4.00065V4.66732C10.0002 4.84413 10.0704 5.0137 10.1954 5.13872C10.3204 5.26375 10.49 5.33398 10.6668 5.33398C10.8436 5.33398 11.0132 5.26375 11.1382 5.13872C11.2633 5.0137 11.3335 4.84413 11.3335 4.66732V4.00065L12.6668 4.00065C12.8436 4.00065 13.0132 4.07089 13.1382 4.19591C13.2633 4.32094 13.3335 4.49051 13.3335 4.66732V6.66732Z"
                  fill="#030303" />
              </svg>
            </div>            
          </div>
        </div>
        <div *ngIf="showValidationMsg" class="validation-msg">
          <p>{{'ageConsentModal.validationMsg' | translate}}</p>
        </div>
        <div class="termsAndCondition">        
          <div class="termsAndCondition-checkbox"><input type="checkbox" (change)="validateDoB()" [(ngModel)]="termsAndCondition" class="checkBox"/> </div>
          <div class="termsAndCondition-text">
            <p>{{'ageConsentModal.IAgreeAll' | translate}} &nbsp;</p>
            <div (click)="loadTermsAndConditions('Terms and Conditions')" class="termsAndCondition-link">
              {{ "footer.termsAndConditions" | translate }}
            </div>
          </div>        
      </div>

      <div class="error-lable" *ngIf="showAgeErrorMessage">
        <div style="width: 24px;height: 24px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="#191C1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.25 11.25H12V16.5H12.75" stroke="#191C1F" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
              fill="#191C1F" />
          </svg>
        </div>
        <div class="error-text">
          <p>{{eligibilityErrorLabel}}</p>
        </div>
      </div>
      </div>

      <div class="footer">
        <button
          pButton
          pRipple
          label='{{"ageConsentModal.btnCancel" | translate }}'
          class="cancel-btn"
          (click)="onCancel()"
        >
      </button>
  
        <button
          pButton
          pRipple
          label='{{"ageConsentModal.btnProceed" | translate }}'
          class="proceed-btn"
          (click)="onSumbit()"
          [disabled]="!enableProceedButton"
        ></button>
      </div>
    </div>
</p-dialog>

<app-ineligable-purchase-modal
[displayModal]="displayEligableModal"
[errorMessage]="eligibilityErrorLabel"
(cancel)="closeEligableModal()"
></app-ineligable-purchase-modal>