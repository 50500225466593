import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ineligable-purchase-modal',
  templateUrl: './ineligable-purchase-modal.component.html',
  styleUrls: ['./ineligable-purchase-modal.component.scss'],
})
export class IneligablePurchaseModalComponent {
  @Input() displayModal: boolean = false;
  @Input() errorMessage: string;
  @Output() cancel = new EventEmitter();

  onCancel() {
    this.cancel.emit();
  }
}
