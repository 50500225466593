import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import {AppDataService, AuthService, PermissionService, StoreService} from '@core/services';
import { environment } from '@environments/environment';
import {isPlatformBrowser} from "@angular/common";
import {GaLocalActionEnum} from "@core/enums/ga-local-action-enum";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import { FooterService } from './footer.service';

@Component({
  selector: 'app-mtn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() toHide: any;
  @Input() footerDetails: any;
  issSellMarketplace: boolean = environment.isStoreCloud
  @Output() scrollToTop: EventEmitter<any> = new EventEmitter<any>();
  isShop: any;
  scConfig: boolean = false
  screenWidth?: any;
  desktopView: boolean;
  isSellerHub: any = '';
  protected readonly environment = environment;
  zoomLevelClass: string = 'default-zoom';
  isMobileTemplate:boolean=false;
  isGoogleAnalytics:boolean=false;
  allData: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateZoomClass();
  }

  footerLinks: any = [
    {
      iconClass: 'fa-brands fa-square-facebook',
      iconLink: 'https://www.facebook.com/YallaSuperMallEG',
      iconName: 'facebook'
    },{
      iconClass: 'fa-brands fa-square-twitter',
      iconLink: 'https://twitter.com/YallaSuperappEG',
      iconName: 'twitter'
    },{
      iconClass: 'fa-brands fa-youtube',
      iconLink: 'https://www.youtube.com/@yallasuperapp3030',
      iconName: 'youtube'
    },{
      iconClass: 'fa-brands fa-instagram',
      iconLink: 'https://www.instagram.com/yallasupermalleg/',
      iconName: 'instagram'
    },{
      iconClass: 'fa-brands fa-tiktok',
      iconLink: 'https://www.tiktok.com/@yallasupermall',
      iconName: 'tiktok'
    },
  ]

  private updateZoomClass() {
    if (isPlatformBrowser(this.platformId)) {
      const zoomLevel = (window.innerWidth / window.screen.availWidth) * 100;
      if (zoomLevel <= 91) {
        this.zoomLevelClass = 'zoom-110';
      }
      else if (zoomLevel <= 112) {
        this.zoomLevelClass = 'zoom-90';
      } else if (zoomLevel <= 125) {
        this.zoomLevelClass = 'zoom-80';
      } else if (zoomLevel <= 134) {
        this.zoomLevelClass = 'zoom-75';
      } else if (zoomLevel <= 150) {
        this.zoomLevelClass = 'zoom-67';
      } else if (zoomLevel <= 200) {
        this.zoomLevelClass = 'zoom-50';
      } else if (zoomLevel <= 300) {
        this.zoomLevelClass = 'zoom-33';
      }
      else if (zoomLevel <= 400) {
        this.zoomLevelClass = 'zoom-25';
      } else {
        this.zoomLevelClass = 'default-zoom';
      }
    }

  }
  constructor(private store: StoreService, public router: Router, @Inject(PLATFORM_ID) private platformId: any,
              private appDataService: AppDataService,
              private permissionService: PermissionService,
              private $gaService: GoogleAnalyticsService,
              private authService: AuthService,
              private footerService: FooterService) {
    this.isMobileTemplate = this.permissionService.hasPermission('Mobile-Layout');
    this.isGoogleAnalytics = this.permissionService.hasPermission('Google Analytics');

    this.scConfig = environment.isStoreCloud;
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.screen.width;
      if (this.screenWidth > 768) {
        this.desktopView = true;
      } else {
        this.desktopView = false;
      }
    }

    if(!this.authService.isMarketplace()){
      const socialLinks = this.appDataService.shopSettingData
      if(socialLinks) {
        for (const link of this.footerLinks) {
          if (socialLinks.hasOwnProperty(link.iconName) && socialLinks[link.iconName] !== null) {
            link.iconUrl = socialLinks[link.iconName];
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.footerService.getAllFooterSectionsWithPages().subscribe((res: any) => {
      this.allData = res.data;

      let result = this.findPageWithTitle('terms and conditions');
      if (!result)
        result = this.findPageWithTitle('termes et conditions');

      if (result) {
        localStorage.setItem('TermsAndConditionsId', result?.id);
      }
    });
  }

  findPageWithTitle(searchText: string): any {
    for (const section of this.allData) {
      const page = section.pages.find((page: any) =>
        page.pageTitle?.toLowerCase().includes(searchText.toLowerCase())
      );
      if (page) {
        return page;
      }
    }
    return undefined;
  }


  reloadCurrentPage(pageId: number, title: string) {
    if(this.isGoogleAnalytics){
    if (title.includes('Terms and Conditions')) {
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_TERMS_AND_CONDITIONS, '', 'TERMS_AND_CONDITION', 1, true);
    } else if (title.includes('Privacy policy')) {
      this.$gaService.event(GaLocalActionEnum.CLICK_ON_PRIVACY_POLICY, '', 'PRIVACY_POLICY', 1, true);
    }
  }

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/about-us/'], {
        queryParams: { pageId: pageId, title: title }, state: { id: pageId }
      })
    );
  }

  navigatePage(url: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/' + url + '/']));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      this.store.subscription('isShop').subscribe({
        next: (res: any) => (this.isShop = res),
      });
    }, 10);
    this.store.subscription('allowedFeature').subscribe({
      next: (res: any) => {
        if (res.length) {
          this.isSellerHub = localStorage.getItem('isSellerHub')
        }
      }
    })
  }

  backToTop() {
    window.scroll(0,0);
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
      this.scrollToTop.emit();
    }
  }
  navigateToSellerHub() {
    if(this.isGoogleAnalytics){
    this.$gaService.event(GaLocalActionEnum.CLICK_ON_SELL_ON_MARKETPLACE_FOOTER, '', '', 1, true);
    }
    if (isPlatformBrowser(this.platformId)) {
      window.open(environment.merchantURL + '?tenantId=' + `${localStorage.getItem('tenantId')}`, '_blank');
    }
  }
}
