import { Component, EventEmitter, Input, OnInit, Output, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from "@angular/common";
import { environment } from '@environments/environment';
@Component({
  selector: 'app-age-consent-modal',
  templateUrl: './age-consent-modal.component.html',
  styleUrls: ['./age-consent-modal.component.scss'],
})

export class AgeConsentModalComponent {
  @Input() displayModal: boolean = false;
  @Input() age: number = 10;
  @Input() eligibilityWarningLabel: string = '';
  @Input() eligibilityErrorLabel: string = '';
  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();
  protected readonly environment = environment;

  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth
    }
  }

  screenWidth: number;
  currentYear = new Date().getFullYear();
  days = Array.from({ length: 31 }, (_, i) => i + 1);
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years = Array.from({ length: 101 }, (_, i) => this.currentYear - i);
  termsAndCondition: boolean;
  productAllowed: boolean;
  ageEligible: boolean;
  enableProceedButton: boolean;
  showAgeErrorMessage: boolean;
  displayEligableModal: boolean;
  errorMessage: string;
  selectedDate: Date;

  selectedDay: number | undefined ;
  selectedMonth: string;
  selectedYear: number | undefined ;
  showValidationMsg: boolean;

  ngOnInit(): void {
    // this.selectedDay = this.days[0];
    // this.selectedMonth = this.months[0];
    // this.selectedYear = this.years[0];
    this.showValidationMsg = false;
    this.productAllowed = false;
    this.enableProceedButton = false;
    this.showAgeErrorMessage = false;
  }


  getDaysInMonth() {
    if (this.selectedYear != undefined) {
      const monthIndex = this.months.indexOf(this.selectedMonth);
      const year = this.selectedYear;

      const daysInMonth = new Date(year, monthIndex + 1, 0).getDate(); // Get number of days in the month
      this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1); // Update day options
      let selectedDay: number = this.selectedDay ?? 0;
      if ((this.selectedDay ?? 0) > daysInMonth) {
        this.selectedDay = daysInMonth; // Adjust selected day if it's invalid
      }
    }
  }

  onMonthOrYearChange() {
    this.getDaysInMonth();
    this.validateDoB();
  }

  validateDoB() {
    if (this.selectedDay == undefined || this.selectedMonth == undefined || this.selectedYear == undefined) {
      this.showValidationMsg = true;
      return;
    }
    else {
      this.showValidationMsg = false;
    }

    this.selectedDate = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), this.selectedDay); // Selected date

    let today = new Date(); // Current date

    const ageLimitDate = new Date();
    ageLimitDate.setFullYear(today.getFullYear() - this.age);

    if (this.selectedDate < ageLimitDate) {
      this.ageEligible = true;
    }
    else this.ageEligible = false;

    if (this.screenWidth > 786)
      this.showAgeErrorMessage = !this.ageEligible;
    else
      this.displayEligableModal = !this.ageEligible;
    this.enableProceedButton = this.ageEligible && this.termsAndCondition;

    this.translate
      .get('ageConsentModal.errorLabel')
      .subscribe((translated: string) => {
        this.errorMessage = translated.replace('*AGE*', this.age.toString());
      });
  }

  loadTermsAndConditions(title: string) {
    const pageId: string = localStorage.getItem('TermsAndConditionsId') ?? '';
    const queryParams = new URLSearchParams({
      pageId: pageId.toString(),
      title: title,
    }).toString();

    let portalPreFix = '';
    if (environment.marketPlaceApplicationName != '') {
      portalPreFix = environment.marketPlaceApplicationName;
    }

    const url = portalPreFix + `/about-us/?${queryParams}`;
    window.open(url, '_blank');
  }

  onSumbit() {
    const formattedDate = this.selectedDate.getFullYear() + '-' + String(this.selectedDate.getMonth() + 1).padStart(2, '0') + '-' + String(this.selectedDate.getDate()).padStart(2, '0');

    this.submit.emit(formattedDate);
  }

  onCancel() {
    this.resetModal();
    this.cancel.emit();
  }

  closeEligableModal(){
    this.displayEligableModal = false;
  }
  onShow(){
    this.resetModal();    
  }
  resetModal(){
    this.selectedDay = undefined;
    this.selectedMonth = '';
    this.selectedYear = undefined;
    this.showAgeErrorMessage = false;
    this.enableProceedButton = false;
    this.displayEligableModal = false;
    this.termsAndCondition = false;
  }
}
