import { Location } from '@angular/common';
import { Directive, HostListener, Output, EventEmitter, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { GaLocalActionEnum } from '@core/enums/ga-local-action-enum';
import { PermissionService } from '@core/services';
import { CustomGAService } from '@core/services/custom-GA.service';
import { Router } from 'express';
import { Renderer } from 'leaflet';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounceTime, first, fromEvent, map, switchMap, take } from 'rxjs';

@Directive({
  selector: '[appGAImpression]',// Use this selector to apply the directive
  standalone:true
})
export class GAImpressionDirective {
  @Input() product:any
  tagName:any = GaLocalActionEnum
  isGoogleAnalytics:boolean = false
  exactPath:any;
  private observer!: IntersectionObserver;
    constructor(private el: ElementRef,
        private googleAnalytics : GoogleAnalyticsService,
        private permissionService:PermissionService,
        private location: Location,
        private _GACustomEvent:CustomGAService) {
            this.isGoogleAnalytics = this.permissionService.hasPermission("Google Analytics");
    }
    ngOnInit(){
      this.getVisibleElements()
      let currentPath = this.location.path()
      if (currentPath.includes('/Portal')) {
        currentPath.replace("/Portal", "")
      }
      let expectedPath = currentPath.split("/")
      this.exactPath = expectedPath[1]?.includes('search') ? "Search" : expectedPath[1]
    }
    private getVisibleElements() {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {

          // Emit true if the element is in the viewport, false otherwise
         if(entry.isIntersecting){
          this.logGoogleImpression();
          observer.unobserve(entry.target);
         }
        });
      });
      this.observer.observe(this.el.nativeElement);
    }
    ngOnDestroy() {
      if (this.observer) {
        this.observer.disconnect();
      }
    }

    logGoogleImpression(){
        if (this.isGoogleAnalytics  ) {
          let pageValue:string = ''
        let query = window.location.search.replace('?','')
        if(query.includes('categoryName')){
          pageValue = query.replace('categoryName=','')
        }
        if(query.includes('q')){
          pageValue = query.replace('q=','')
        }
        let itemListName  = this.exactPath?.includes('category') ?  this.product.categoryName : pageValue
          this.googleAnalytics.event(this.tagName.IMPRESSIONS,
            'product', 'IMPRESSIONS', 1 , true, {
            "product_name": this.product.productName ? this.product?.productName : this.product.name,
            "category_name": this.product.categoryName,
            "product_SKU": this.product?.specProductDetails?.skuAutoGenerated ?  this.product?.specProductDetails?.skuAutoGenerated: this.product['skuAutoGenerated'],
            "product_ID": this.product.productId,
            "shop_ID": this.product.shopId,
            "product_tags":(this.product?.isBest || this.product.bestSeller )? 'Best Seller' : (this.product?.isNew || this.product.newArrival )? 'New Arrival' : (this.product?.isHot  || this.product.hotDeals)? 'Hot Deals':'None',
            "promotion":this.product?.promotionName ? this.product?.promotionName : 'None',
            "page_label":this.exactPath ?  this.exactPath : 'Home',
            "page_value": itemListName
          });
            this._GACustomEvent.viewItemListEvent(this.product,itemListName)
        }
      }
}
