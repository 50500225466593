export const environment = {
  production: true,
  apiEndPoint: 'https://market.momo.africa:1010',//'https://momouat.ug:1010',
  securityApiUrl:'https://market.momo.africa:1010/sso-authenticator/connect/token',
  featureProductsLimit: 100,
  platform: 'storecloud',
  isStoreCloud: false,
  isMarketPlace: true,
  testing: false,
  excludingTenants: [0],
  defaultTenant: '1',
  referer:1,
  merchantURL: 'https://market.momo.africa/sell',
  maintenanceMode: false,
  marketPlaceHostName: "market.momo.africa",
  client_id:'',
  client_secret:'82C0CFC2-F45A-469A-B124-DDBE23A8C13B',
  scope:'cc.access',
  marketPlaceApplicationName: '/Portal'
};