<p-dialog class="ineligable-purchase" [(visible)]="displayModal"
  [resizable]="false" [closable]="false" [modal]="true">

  <div class="body">
    <div class="icon-header">
      <svg xmlns="http://www.w3.org/2000/svg" width="73" height="82" viewBox="0 0 73 82" fill="none">
        <ellipse cx="52.5667" cy="27.8759" rx="4.09208" ry="4.0495" fill="white" />
        <mask id="mask0_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="10" width="72"
          height="72">
          <path
            d="M36.1901 81.1476C55.7362 81.1476 71.5814 65.3024 71.5814 45.7563C71.5814 26.2102 55.7362 10.365 36.1901 10.365C16.6441 10.365 0.798828 26.2102 0.798828 45.7563C0.798828 65.3024 16.6441 81.1476 36.1901 81.1476Z"
            fill="white" />
        </mask>
        <g mask="url(#mask0_6694_1386)">
          <path
            d="M21.8908 92.2665C16.2696 123.847 22.4512 176.74 23.6323 179.571C24.8135 182.402 32.9234 180.795 32.4521 178.671C31.9808 176.548 35.6565 122.821 37.4354 123.106C39.2143 123.39 43.7865 135.84 38.4096 169.685C38.128 171.461 44.3584 174.292 45.5712 170.834C51.1091 155.06 59.3541 131.012 55.0204 88.519C54.0088 78.6043 24.1352 79.6676 21.8936 92.2665H21.8908Z"
            fill="#F7E600" />
          <path
            d="M55.0165 88.519C51.9329 68.1636 62.7184 52.803 57.1345 42.5692C51.5507 32.3355 27.7926 34.6892 22.91 37.9682C12.9953 44.6298 23.8986 73.8739 21.8869 92.2665C21.5765 95.0943 55.9706 94.8414 55.0136 88.519H55.0165Z"
            fill="#F7E500" />
          <mask id="mask1_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="18" y="35" width="41"
            height="59">
            <path
              d="M55.0165 88.519C51.9329 68.1636 62.7184 52.803 57.1345 42.5692C51.5507 32.3355 27.7926 34.6892 22.91 37.9682C12.9953 44.6298 23.8986 73.8739 21.8869 92.2665C21.5765 95.0943 55.9706 94.8414 55.0136 88.519H55.0165Z"
              fill="white" />
          </mask>
          <g mask="url(#mask1_6694_1386)">
            <path
              d="M54.112 44.6643C61.4547 54.8635 63.9722 90.3698 58.1239 84.3405C52.2728 78.3111 46.7579 93.2465 46.824 100.937C46.9591 116.286 7.44667 91.663 5.13323 90.1312C5.00966 92.6717 9.7285 42.4801 15.3354 36.6031C23.3649 28.1885 41.654 36.537 45.0192 37.859C47.4304 38.8074 51.7383 41.368 54.112 44.6614V44.6643Z"
              fill="#FFC41B" />
          </g>
          <path
            d="M42.1744 35.5025C43.1946 39.4856 30.4089 40.7214 30.6359 36.2755C30.8084 32.8643 31.2509 29.6025 27.4805 21.6477L40.7863 17.6215C40.7863 17.6215 41.4933 32.8384 42.1744 35.5025Z"
            fill="#C29A6D" />
          <mask id="mask2_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="17" width="16"
            height="23">
            <path
              d="M42.1744 35.5025C43.1946 39.4856 30.4089 40.7214 30.6359 36.2755C30.8084 32.8643 31.2509 29.6025 27.4805 21.6477L40.7863 17.6215C40.7863 17.6215 41.4933 32.8384 42.1744 35.5025Z"
              fill="white" />
          </mask>
          <g mask="url(#mask2_6694_1386)">
            <path
              d="M33.0022 30.4819C33.6201 32.6401 37.6722 26.8062 37.9711 29.5019C38.2872 32.3297 38.9884 34.2983 42.2301 36.5342C44.1614 37.8648 29.5192 47.6818 29.5192 47.6818L23.3691 21.5557C23.3691 21.5557 32.3815 28.3236 32.9994 30.4819H33.0022Z"
              fill="#AA7D50" />
          </g>
          <path
            d="M56.7933 67.6262C60.2477 69.0028 65.1936 66.4365 66.8115 62.6574C68.8175 57.9673 65.2482 52.4524 62.8571 48.7595C62.4462 48.1273 56.0691 38.4079 52.7067 39.7356C48.0569 41.5692 49.0915 64.5541 56.7933 67.6262Z"
            fill="#F7E500" />
          <mask id="mask3_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="49" y="39" width="19"
            height="30">
            <path
              d="M56.7933 67.6262C60.2477 69.0028 65.1936 66.4365 66.8115 62.6574C68.8175 57.9673 65.2482 52.4524 62.8571 48.7595C62.4462 48.1273 56.0691 38.4079 52.7067 39.7356C48.0569 41.5692 49.0915 64.5541 56.7933 67.6262Z"
              fill="white" />
          </mask>
          <g mask="url(#mask3_6694_1386)">
            <path
              d="M62.7673 73.7619C62.8909 66.3991 56.3299 44.7706 49.789 40.5518C44.4983 37.1377 50.9127 69.5373 53.0077 70.4311C55.1028 71.3277 62.7702 73.7619 62.7702 73.7619H62.7673Z"
              fill="#FFC51D" />
          </g>
          <path
            d="M45.5695 19.7963C45.9747 17.8104 45.3626 16.0086 44.2025 15.772C43.0423 15.5353 41.7734 16.9533 41.3683 18.9393C40.9632 20.9252 41.5752 22.727 42.7354 22.9636C43.8955 23.2003 45.1644 21.7822 45.5695 19.7963Z"
            fill="#C29A6D" />
          <path
            d="M29.1751 23.0505C30.3353 22.8138 30.9473 21.0121 30.5422 19.0261C30.1371 17.0402 28.8682 15.6222 27.708 15.8588C26.5479 16.0955 25.9358 17.8973 26.341 19.8832C26.7461 21.8691 28.015 23.2872 29.1751 23.0505Z"
            fill="#AA7D50" />
          <path
            d="M44.4362 16.4259C44.4276 24.5963 41.0135 30.7147 35.9153 30.7147C30.8171 30.7147 27.288 24.2801 27.2852 16.1098C27.2852 5.12315 26.9259 4.97083 35.9383 6.04565C44.8615 7.7642 44.4477 4.06558 44.4362 16.4259Z"
            fill="#C29A6D" />
          <mask id="mask4_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="5" width="18"
            height="26">
            <path
              d="M44.4362 16.4259C44.4276 24.5963 41.0135 30.7147 35.9153 30.7147C30.8171 30.7147 27.288 24.2801 27.2852 16.1098C27.2852 5.12315 26.9259 4.97083 35.9383 6.04565C44.8615 7.7642 44.4477 4.06558 44.4362 16.4259Z"
              fill="white" />
          </mask>
          <g mask="url(#mask4_6694_1386)">
            <path
              d="M36.0174 30.7147C33.7902 29.2835 36.5778 28.1569 35.7128 27.0275C35.3335 26.5332 35.4455 25.9326 35.8048 25.7429C34.5949 22.1765 36.9859 22.0615 36.8106 21.4781C36.6353 20.8948 34.9455 21.8862 34.4742 20.7884C34.0805 19.8688 35.8163 19.5872 35.4369 13.4429C35.2875 11.0461 33.1263 9.20399 35.6208 5.24959C38.1153 1.29231 29.2984 -1.19355 23.7174 5.15475C16.5817 13.2705 23.8985 32.5683 36.0174 30.7118V30.7147Z"
              fill="#AA7D50" />
          </g>
          <path
            d="M24.8664 6.0658C24.4669 8.99424 26.1768 12.121 31.117 10.0892C37.4452 7.48547 42.8049 7.31591 44.3481 9.0086C44.055 3.49084 39.2959 -0.222161 32.8786 0.519289C28.4903 1.02796 25.2859 2.98504 24.8635 6.0658H24.8664Z"
            fill="#3B2416" />
          <mask id="mask5_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="24" y="0" width="21"
            height="11">
            <path
              d="M24.8664 6.0658C24.4669 8.99424 26.1768 12.121 31.117 10.0892C37.4452 7.48547 42.8049 7.31591 44.3481 9.0086C44.055 3.49084 39.2959 -0.222161 32.8786 0.519289C28.4903 1.02796 25.2859 2.98504 24.8635 6.0658H24.8664Z"
              fill="white" />
          </mask>
          <g mask="url(#mask5_6694_1386)">
            <path
              d="M48.5554 14.2332C49.2394 -9.02473 14.0607 -1.58723 20.774 -0.265269C26.4039 0.844033 33.8442 1.18602 34.3012 8.41947C34.6 13.1556 47.8714 37.4912 48.5554 14.2332Z"
              fill="#5F3A1A" />
          </g>
          <path
            d="M40.2147 15.6931C41.7463 15.6931 42.9879 14.8619 42.9879 13.8366C42.9879 12.8113 41.7463 11.9801 40.2147 11.9801C38.683 11.9801 37.4414 12.8113 37.4414 13.8366C37.4414 14.8619 38.683 15.6931 40.2147 15.6931Z"
            fill="#AA7D50" />
          <path
            d="M40.4081 15.269C40.4168 14.712 40.1962 14.2568 39.9153 14.2524C39.6344 14.248 39.3996 14.696 39.3908 15.253C39.3821 15.81 39.6027 16.2652 39.8836 16.2696C40.1645 16.274 40.3993 15.826 40.4081 15.269Z"
            fill="black" />
          <path
            d="M32.1712 16.2691C32.4521 16.2647 32.6727 15.8096 32.6639 15.2526C32.6552 14.6955 32.4204 14.2475 32.1395 14.252C31.8586 14.2564 31.638 14.7115 31.6467 15.2685C31.6555 15.8256 31.8903 16.2736 32.1712 16.2691Z"
            fill="black" />
          <path
            d="M37.748 12.8107C38.4205 11.5462 40.0155 10.7588 41.4266 11.2732C42.3059 11.5864 42.852 12.6095 42.6795 13.4659C42.2571 13.0894 41.9065 12.7733 41.5559 12.6181C40.3201 12.1066 39.0815 12.7187 37.748 12.8107V12.8107Z"
            fill="#5F3A1A" />
          <path
            d="M34.1449 13.0722C32.8114 12.9802 31.5757 12.3681 30.337 12.8796C29.9864 13.0348 29.6358 13.3509 29.2134 13.7274C29.0409 12.871 29.587 11.8479 30.4664 11.5347C31.8774 11.0203 33.4695 11.8106 34.1449 13.0722Z"
            fill="#3B2416" />
          <path
            d="M27.3711 18.271C27.3711 18.271 27.6757 34.4535 35.9265 34.603C44.1773 34.7496 44.3469 18.6187 44.3469 18.6187C44.3469 18.6187 41.5736 21.892 36.0673 22.0357C30.5467 22.1794 27.3711 18.2738 27.3711 18.2738V18.271Z"
            fill="#5F3A1A" />
          <mask id="mask6_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="18" width="18"
            height="17">
            <path
              d="M27.3711 18.271C27.3711 18.271 27.6757 34.4535 35.9265 34.603C44.1773 34.7496 44.3469 18.6187 44.3469 18.6187C44.3469 18.6187 41.5736 21.892 36.0673 22.0357C30.5467 22.1794 27.3711 18.2738 27.3711 18.2738V18.271Z"
              fill="white" />
          </mask>
          <g mask="url(#mask6_6694_1386)">
            <path
              d="M39.2299 33.6029C37.0027 32.1717 36.1952 29.5939 35.9222 27.5765C35.5428 27.0822 35.9739 25.901 36.3331 25.7113C34.583 21.9897 36.9826 22.0616 36.8102 21.481C36.6349 20.8977 35.5227 21.9236 35.0514 20.8229C34.6577 19.9033 35.8158 19.5872 35.4365 13.4458C35.287 11.049 33.1259 9.20689 35.6204 5.25249C38.1149 1.29521 29.298 -1.19066 23.717 5.15765C16.5812 13.2734 32.6287 43.9976 39.2299 33.6029Z"
              fill="#3B2416" />
          </g>
          <path
            d="M35.6601 27.6713C36.2463 27.6483 37.0108 27.4644 37.4821 27.7862C37.9046 28.0765 38.3213 27.0477 38.3213 27.0477C38.3213 27.0477 38.5684 24.7802 36.6631 24.6767C34.7577 24.5733 32.8926 26.8465 32.8926 26.8465C33.4673 26.7631 34.9819 27.6971 35.663 27.6713H35.6601Z"
            fill="#AA7D50" />
          <mask id="mask7_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="32" y="24" width="7"
            height="4">
            <path
              d="M35.6601 27.6713C36.2463 27.6483 37.0108 27.4644 37.4821 27.7862C37.9046 28.0765 38.3213 27.0477 38.3213 27.0477C38.3213 27.0477 38.5684 24.7802 36.6631 24.6767C34.7577 24.5733 32.8926 26.8465 32.8926 26.8465C33.4673 26.7631 34.9819 27.6971 35.663 27.6713H35.6601Z"
              fill="white" />
          </mask>
          <g mask="url(#mask7_6694_1386)">
            <path
              d="M35.9215 28.4731C35.979 28.3035 36.0595 28.0104 36.0221 27.6454C35.9732 27.1224 35.7232 26.766 35.6054 26.6166L35.5192 25.3895C35.0852 24.8894 35.6198 24.3262 36.0595 23.7198L31.3492 24.6796L30.5762 28.0679C32.358 28.203 34.1397 28.338 35.9186 28.4731H35.9215Z"
              fill="#C29A6D" />
          </g>
          <path
            d="M38.3193 27.0477C38.3193 27.0477 37.5779 25.0906 32.8906 26.8494C32.8906 26.8494 34.6178 25.7774 38.3193 27.0477Z"
            fill="#010101" />
          <path
            d="M21.349 62.8154C22.2743 54.9871 19.1677 50.501 13.3051 61.2377C9.9542 71.3823 10.0059 86.0245 11.2704 99.3447C11.6986 103.868 7.53443 104.753 8.25864 110.831C8.44831 112.412 9.52312 112.975 9.97432 112.576C10.0979 113.383 11.279 114.065 11.7101 113.633C11.7877 114.361 12.4659 115.22 13.3626 115.312C13.2821 116.412 15.2392 117.404 15.7479 116.211L17.659 110.28C17.2854 112.809 17.863 114.59 18.8947 115.346C19.9666 116.131 20.5472 115.68 19.8891 114.338C19.2309 112.995 19.6419 110.992 19.7224 108.228C19.8057 105.325 17.3974 102.374 17.2049 100.937C15.9117 91.2233 17.6388 81.8057 21.349 62.8211V62.8154Z"
            fill="#AA7D50" />
          <mask id="mask8_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="8" y="54" width="14"
            height="63">
            <path
              d="M21.349 62.8154C22.2743 54.9871 19.1677 50.501 13.3051 61.2377C9.9542 71.3823 10.0059 86.0245 11.2704 99.3447C11.6986 103.868 7.53443 104.753 8.25864 110.831C8.44831 112.412 9.52312 112.975 9.97432 112.576C10.0979 113.383 11.279 114.065 11.7101 113.633C11.7877 114.361 12.4659 115.22 13.3626 115.312C13.2821 116.412 15.2392 117.404 15.7479 116.211L17.659 110.28C17.2854 112.809 17.863 114.59 18.8947 115.346C19.9666 116.131 20.5472 115.68 19.8891 114.338C19.2309 112.995 19.6419 110.992 19.7224 108.228C19.8057 105.325 17.3974 102.374 17.2049 100.937C15.9117 91.2233 17.6388 81.8057 21.349 62.8211V62.8154Z"
              fill="white" />
          </mask>
          <g mask="url(#mask8_6694_1386)">
            <path
              d="M17.6019 57.2172C11.7881 82.4552 13.8745 117.45 15.7741 119.355C17.6737 121.261 -2.32246 123.005 0.180655 114.682C2.68377 106.36 -0.210187 64.6978 -1.23327 61.7176C-2.25636 58.7374 17.6047 57.2143 17.6047 57.2143L17.6019 57.2172Z"
              fill="#AA7E51" />
          </g>
          <path
            d="M21.4618 65.7525C20.9675 68.8016 9.87166 66.7698 10.9149 63.0511C11.9581 59.3352 19.9904 36.3014 25.2323 37.3475C30.4253 38.382 23.1775 55.2112 21.4647 65.7525H21.4618Z"
            fill="#FFC51D" />
          <mask id="mask9_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="10" y="37" width="18"
            height="31">
            <path
              d="M21.4618 65.7525C20.9675 68.8016 9.87166 66.7698 10.9149 63.0511C11.9581 59.3352 19.9904 36.3014 25.2323 37.3475C30.4253 38.382 23.1775 55.2112 21.4647 65.7525H21.4618Z"
              fill="white" />
          </mask>
          <g mask="url(#mask9_6694_1386)">
            <path
              d="M15.1449 71.8823C14.8403 64.3586 21.4271 42.4083 28.4019 38.2469C34.0404 34.8817 27.8617 67.8388 25.6172 68.7039C23.3727 69.5718 15.1449 71.8795 15.1449 71.8795V71.8823Z"
              fill="#FFC51D" />
          </g>
          <path
            d="M34.2789 56.8752C34.2789 56.8752 30.8245 55.6969 27.1201 57.2574C27.1201 57.2574 22.9732 58.8122 24.4705 60.9934C24.4705 60.9934 24.6055 61.2751 24.8757 61.5653C24.8757 61.5653 23.5825 63.3931 25.1918 63.8328C25.1918 63.8328 24.1457 65.5686 25.6028 66.1318C25.6028 66.1318 25.5654 68.1234 27.3357 67.9998C29.1088 67.8763 30.0917 67.2641 30.4739 67.4394C30.8561 67.6147 30.9309 68.52 30.6377 70.0719C30.3475 71.6237 29.0887 73.3969 31.543 74.8971C31.543 74.8971 32.3994 74.5493 32.4799 73.5205C32.5603 72.4916 33.0316 69.7443 33.3535 69.3477C34.0202 68.5286 34.4456 68.5574 35.4169 67.6722C36.2503 66.9107 37.1901 65.5542 37.4889 62.9563C40.9749 63.5195 43.8804 63.6517 45.9898 63.6546L56.2063 63.0396C56.2063 63.0396 59.606 63.4477 61.27 61.9906C61.27 61.9906 64.0288 60.5307 64.1754 59.3898C64.322 58.2489 64.8852 56.5045 63.7616 55.3492C63.2673 54.8434 62.9167 54.1882 62.1781 53.6939C60.129 52.3173 57.4966 52.9409 56.3327 53.191C52.7088 53.9755 47.8549 54.8405 41.8371 55.9326C40.426 56.3665 39.0092 56.7976 37.5867 57.2258C36.9688 57.4126 36.3538 57.5965 35.7388 57.7805C35.6612 57.6425 35.4945 57.3781 35.1784 57.1683C34.8077 56.9212 34.4427 56.8838 34.2846 56.8781L34.2789 56.8752Z"
            fill="#AA7D50" />
          <path
            d="M27.1485 58.5966C27.1485 58.5966 27.1053 59.3984 29.87 58.4616C29.87 58.4616 32.0426 57.815 31.0827 59.5076C31.0827 59.5076 28.1888 61.0854 24.8379 61.6199"
            stroke="#5F3A1A" stroke-width="0.382609" stroke-miterlimit="10" />
          <path
            d="M25.3828 63.7753C25.3828 63.7753 31.8231 62.5166 31.6305 61.048C31.438 59.5795 30.8517 59.6312 30.8517 59.6312"
            stroke="#5F3A1A" stroke-width="0.382609" stroke-miterlimit="10" />
          <path
            d="M26.0254 65.9422C26.0254 65.9422 29.4596 65.8186 31.2701 63.9132C31.2701 63.9132 31.9484 63.3299 31.7903 62.7264C31.6322 62.1228 31.3161 61.686 31.3161 61.686"
            stroke="#5F3A1A" stroke-width="0.382609" stroke-miterlimit="10" />
          <path d="M31.3086 66.537C31.3086 66.537 33.9985 64.6518 31.5557 63.5942" stroke="#5F3A1A"
            stroke-width="0.382609" stroke-miterlimit="10" />
          <path d="M41.0215 57.7718L43.1395 60.1485" stroke="#5F3A1A" stroke-width="0.382609" stroke-miterlimit="10" />
          <path
            d="M57.6289 52.9409C57.6289 52.9409 60.293 62.6919 60.7815 62.3384C61.2701 61.9849 63.9686 60.6974 64.1755 59.3869C64.3824 58.0765 64.7129 56.4642 63.7617 55.3463C62.8104 54.2284 61.6695 52.7656 59.6492 52.8547C57.6289 52.9438 58.925 52.6938 57.6289 52.9409V52.9409Z"
            fill="#C19A6D" />
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M36.34 11.9541C17.4655 11.9541 2.9517 26.9609 2.9517 45.4574C2.9517 63.9168 17.4283 78.2286 36.34 78.2286C55.2431 78.2286 69.6013 63.8837 69.6013 45.4157C69.6013 26.9148 55.2102 11.9541 36.34 11.9541ZM0.273438 45.4574C0.273438 25.5141 15.9543 9.27582 36.34 9.27582C56.7302 9.27582 72.2796 25.4768 72.2796 45.4157C72.2796 65.3877 56.6973 80.9068 36.34 80.9068C15.9915 80.9068 0.273438 65.4379 0.273438 45.4574Z"
          fill="#ED3434" />
        <path
          d="M45.2202 19.4686C45.6229 17.5118 45.0144 15.7365 43.8611 15.5033C42.7078 15.2701 41.4464 16.6674 41.0437 18.6242C40.6409 20.581 41.2494 22.3563 42.4027 22.5895C43.556 22.8227 44.8174 21.4254 45.2202 19.4686Z"
          fill="#C29A6D" />
        <path
          d="M28.9236 22.673C30.0769 22.4398 30.6854 20.6645 30.2827 18.7077C29.8799 16.7509 28.6185 15.3536 27.4652 15.5868C26.3119 15.82 25.7034 17.5954 26.1062 19.5522C26.5089 21.509 27.7703 22.9062 28.9236 22.673Z"
          fill="#AA7D50" />
        <path
          d="M44.0949 16.1488C44.0863 24.1993 40.6923 30.228 35.6242 30.228C30.556 30.228 27.0478 23.8878 27.0449 15.8373C27.0449 5.01178 26.6878 4.8617 35.647 5.92075C44.5177 7.6141 44.1063 3.96972 44.0949 16.1488Z"
          fill="#C29A6D" />
        <mask id="mask10_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="5" width="18"
          height="26">
          <path
            d="M44.0949 16.1488C44.0863 24.1993 40.6923 30.228 35.6242 30.228C30.556 30.228 27.0478 23.8878 27.0449 15.8373C27.0449 5.01178 26.6878 4.8617 35.647 5.92075C44.5177 7.6141 44.1063 3.96972 44.0949 16.1488Z"
            fill="white" />
        </mask>
        <g mask="url(#mask10_6694_1386)">
          <path
            d="M35.7272 30.2251C33.5131 28.815 36.2843 27.7049 35.4244 26.5921C35.0473 26.105 35.1587 25.5132 35.5158 25.3263C34.313 21.8122 36.69 21.6989 36.5157 21.1241C36.3415 20.5493 34.6616 21.5262 34.1931 20.4445C33.8017 19.5383 35.5272 19.2608 35.1501 13.2067C35.0016 10.845 32.8532 9.02993 35.333 5.13352C37.8128 1.23428 29.0478 -1.21513 23.4997 5.04008C16.406 13.0368 23.6797 32.0516 35.7272 30.2223V30.2251Z"
            fill="#AA7D50" />
        </g>
        <path
          d="M24.6395 5.93773C24.2424 8.82323 25.9422 11.9041 30.8532 9.9021C37.1441 7.33659 42.4723 7.16952 44.0064 8.83739C43.715 3.40337 38.984 -0.255177 32.6045 0.475399C28.242 0.976609 25.0566 2.90499 24.6366 5.94056L24.6395 5.93773Z"
          fill="#3B2416" />
        <mask id="mask11_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="24" y="0" width="21"
          height="11">
          <path
            d="M24.6395 5.93773C24.2424 8.82323 25.9422 11.9041 30.8532 9.9021C37.1441 7.33659 42.4723 7.16952 44.0064 8.83739C43.715 3.40337 38.984 -0.255177 32.6045 0.475399C28.242 0.976609 25.0566 2.90499 24.6366 5.94056L24.6395 5.93773Z"
            fill="white" />
        </mask>
        <g mask="url(#mask11_6694_1386)">
          <path
            d="M48.1874 13.9882C48.8674 -8.92866 13.896 -1.60307 20.5698 -0.300493C26.1664 0.79254 33.5629 1.12951 34.0172 8.25688C34.3143 12.9235 47.5075 36.9023 48.1874 13.9854V13.9882Z"
            fill="#5F3A1A" />
        </g>
        <path
          d="M39.8995 15.4267C41.4221 15.4267 42.6564 14.6077 42.6564 13.5975C42.6564 12.5872 41.4221 11.7682 39.8995 11.7682C38.3769 11.7682 37.1426 12.5872 37.1426 13.5975C37.1426 14.6077 38.3769 15.4267 39.8995 15.4267Z"
          fill="#AA7D50" />
        <path
          d="M40.0934 15.0064C40.1021 14.4576 39.8828 14.0091 39.6035 14.0048C39.3243 14.0004 39.0909 14.4418 39.0822 14.9907C39.0735 15.5396 39.2928 15.988 39.572 15.9924C39.8513 15.9967 40.0847 15.5553 40.0934 15.0064Z"
          fill="black" />
        <path
          d="M31.9043 15.9928C32.1835 15.9884 32.4029 15.5399 32.3942 14.9911C32.3855 14.4422 32.152 14.0008 31.8728 14.0052C31.5936 14.0095 31.3742 14.458 31.3829 15.0068C31.3916 15.5557 31.6251 15.9971 31.9043 15.9928Z"
          fill="black" />
        <path
          d="M37.4492 12.5837C37.7035 12.0825 38.1006 11.7059 38.5548 11.4114C39.0148 11.1254 39.5605 10.9187 40.1747 10.9073C40.4804 10.9045 40.8004 10.947 41.1061 11.0659C41.4089 11.182 41.6889 11.3774 41.8917 11.6153C42.3088 12.091 42.4402 12.6828 42.3517 13.2265C41.9317 12.8555 41.5832 12.544 41.2346 12.3911C40.8946 12.2269 40.5775 12.1929 40.1976 12.1957C39.8204 12.2014 39.3948 12.2637 38.9405 12.3458C38.4834 12.4251 38.0035 12.5214 37.4492 12.5809V12.5837Z"
          fill="#5F3A1A" />
        <path
          d="M33.8662 12.8442C33.3091 12.7848 32.832 12.6885 32.3749 12.6092C31.9206 12.5271 31.4949 12.4619 31.1178 12.4591C30.7379 12.4591 30.4207 12.4931 30.0808 12.6545C29.7322 12.8074 29.3837 13.1189 28.9637 13.4899C28.8752 12.9462 29.0066 12.3543 29.4237 11.8786C29.6294 11.6408 29.9065 11.4454 30.2093 11.3293C30.5122 11.2103 30.835 11.165 31.1407 11.1707C31.7549 11.1849 32.3006 11.3887 32.7605 11.6747C33.2177 11.9692 33.6119 12.343 33.8662 12.8471V12.8442Z"
          fill="#3B2416" />
        <path
          d="M27.1309 17.9639C27.1309 17.9639 27.4337 33.9092 35.6359 34.0564C43.838 34.2009 44.0066 18.3066 44.0066 18.3066C44.0066 18.3066 41.2497 21.5319 35.7758 21.6734C30.2877 21.815 27.1309 17.9668 27.1309 17.9668V17.9639Z"
          fill="#5F3A1A" />
        <mask id="mask12_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="27" y="17" width="18"
          height="18">
          <path
            d="M27.1309 17.9639C27.1309 17.9639 27.4337 33.9092 35.6359 34.0564C43.838 34.2009 44.0066 18.3066 44.0066 18.3066C44.0066 18.3066 41.2497 21.5319 35.7758 21.6734C30.2877 21.815 27.1309 17.9668 27.1309 17.9668V17.9639Z"
            fill="white" />
        </mask>
        <g mask="url(#mask12_6694_1386)">
          <path
            d="M38.9209 33.071C36.7068 31.6608 35.904 29.1208 35.6326 27.1329C35.2555 26.6459 35.6841 25.4821 36.0412 25.2952C34.3013 21.6281 36.6868 21.6989 36.5154 21.1269C36.3412 20.5521 35.2355 21.563 34.767 20.4785C34.3756 19.5723 35.5269 19.2608 35.1498 13.2095C35.0013 10.8479 32.8529 9.03277 35.3327 5.13636C37.8125 1.23712 29.0475 -1.21229 23.4994 5.04292C16.4057 13.0396 32.3586 43.3132 38.9209 33.071Z"
            fill="#3B2416" />
        </g>
        <path
          d="M35.3723 27.2264C35.9551 27.2037 36.715 27.0225 37.1836 27.3396C37.6035 27.6256 38.0178 26.6119 38.0178 26.6119C38.0178 26.6119 38.2635 24.3777 36.3693 24.2757C34.4752 24.1738 32.6211 26.4137 32.6211 26.4137C33.1925 26.3316 34.6981 27.2519 35.3751 27.2264H35.3723Z"
          fill="#AA7D50" />
        <mask id="mask13_6694_1386" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="32" y="24" width="7"
          height="4">
          <path
            d="M35.3723 27.2264C35.9551 27.2037 36.715 27.0225 37.1836 27.3396C37.6035 27.6256 38.0178 26.6119 38.0178 26.6119C38.0178 26.6119 38.2635 24.3777 36.3693 24.2757C34.4752 24.1738 32.6211 26.4137 32.6211 26.4137C33.1925 26.3316 34.6981 27.2519 35.3751 27.2264H35.3723Z"
            fill="white" />
        </mask>
        <g mask="url(#mask13_6694_1386)">
          <path
            d="M35.6302 28.0164C35.6874 27.8494 35.7674 27.5605 35.7302 27.2009C35.6817 26.6855 35.4331 26.3344 35.316 26.1872L35.2303 24.978C34.7989 24.4853 35.3303 23.9303 35.7674 23.3328L31.0849 24.2786L30.3164 27.6172C32.0877 27.7502 33.859 27.8833 35.6274 28.0164H35.6302Z"
            fill="#C29A6D" />
        </g>
        <path
          d="M38.0158 26.6119C38.0158 26.6119 37.2787 24.6835 32.6191 26.4165C32.6191 26.4165 34.3361 25.3603 38.0158 26.6119Z"
          fill="#010101" />
        <path
          d="M52.0595 21.6951C48.9092 21.6951 46.3477 24.2594 46.3205 27.3962C46.307 28.9267 46.8931 30.3811 47.9785 31.4638C49.0503 32.5601 50.4911 33.1598 52.0215 33.1734H52.0595C55.2099 33.1734 57.7714 30.6091 57.7985 27.4722C57.8121 24.3109 55.2614 21.7222 52.0595 21.6951ZM54.4827 29.3093C54.6346 29.4612 54.6346 29.7055 54.4827 29.8574C54.4067 29.9334 54.3036 29.9714 54.214 29.9714C54.1109 29.9714 54.0241 29.9334 53.9454 29.8574L52.0595 27.9824L50.1845 29.8574C50.1085 29.9334 50.0054 29.9714 49.9159 29.9714C49.8263 29.9714 49.7124 29.9334 49.6364 29.8574C49.4844 29.7055 49.4844 29.4612 49.6364 29.3093L51.5114 27.4342L49.6364 25.5456C49.4844 25.3936 49.4844 25.1494 49.6364 24.9975C49.7884 24.8455 50.0326 24.8455 50.1845 24.9975L52.0595 26.8861L53.9345 25.011C54.0865 24.8591 54.3307 24.8591 54.4827 25.011C54.6346 25.163 54.6346 25.4072 54.4827 25.5592L52.6077 27.4342L54.4827 29.3093Z"
          fill="#ED3434" />
      </svg>
    </div>
    <div class="message">
      <p>{{errorMessage}}</p>
    </div>
    <div class="footer-button">
      <button pButton pRipple label="{{ 'auth.registerPassword.okButton' |translate}}" class="cancel-btn"
        (click)="onCancel()"></button>
    </div>
  </div>
</p-dialog>